import {
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Select,
  MenuItem,
  Grid,
  FormGroup,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

import { JEFormData } from "./api";
import {
  TITLE,
  VERSION_ALERT,
  STEP1_TITLE,
  STEP2_TITLE,
  STEP3_TITLE,
  PPM_LABEL,
  PPM_SUBTITLE,
  PPM_OPTIONS,
  TRIP_LABEL,
  TRIP_SUBTITLE,
  TRIP_OPTIONS,
  SETTING_LABEL,
  SETTING_SUBLABEL,
  SETTING_OPTIONS,
  MONTH_LABEL, 
  MONTH_OPTIONS,
  COUNTRY_LABEL, 
  COUNTRY_SUBLABEL,
  COUNTRY_OPTIONS,
  VACCINE_STATUS_LABEL,
  VACCINE_STATUS_OPTIONS,
  COMORBIDITIES_LABEL,
  COMORBIDITIES_OPTIONS,
  VACCINE_TYPE_LABEL,
  VACCINE_TYPE_OPTIONS,
  AGE_LABEL,
  AGE_OPTIONS,
  SEX_LABEL,
  SEX_OPTIONS,
  FIELD_REQUIRED,
  SUBMIT_LABEL,
  TOS_HEADING,
  TOS_1,
  TOS_2,
  TOS_3,
  TOS_TITLE,
  TOS_TEXT,
  MAP_TITLE,
  MAP_TEXT,
  MAP_LINK_NAME,
  MONTHS_NUMBER,
  JE_TITLE,
  JE_DESCRIPTION,
} from "./constants";
import { useState } from "react";
// import { getValue } from "@testing-library/user-event/dist/utils";

const useStyles = makeStyles((theme) => ({
  formComp: {
    marginTop: "0.5rem",
    marginBottom: "0.7rem",
  },
  indent: {
    paddingLeft: "2rem",
  },
  ctOption: {
    marginTop: "0rem",
    marginBottom: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  ctDescription: {
    color: "#777",
    fontSize: "0.9rem",
  },
  message: {
    margin: "2rem 1rem",
  },
}));

type FormInputs = {
  callback: (form: JEFormData) => void;
};

export type FullFormData = {
  tos: boolean;
  age?: string; 
  sex?: string;
  vaccine_status: string;
  comorbidities?: string;
  vaccine_type: string;
  ppm?: string;
  trip_length: string;
  setting?: string;
  //departure_month: string;
  months: string[];
  country: string;
};

export default function Form({ callback }: FormInputs) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FullFormData>({
    mode: "onBlur",
    defaultValues: {
    },
  });
  const submit = handleSubmit((form: FullFormData) => {
    callback({
      tos: form.tos,
      age: form.age ? form.age : "None",
      sex: form.sex ? form.sex : "None",
      vaccine_status: form.vaccine_status,
      comorbidities: form.comorbidities ? form.comorbidities : "None" ,
      vaccine_type: form.vaccine_type,
      ppm: form.ppm ? form.ppm : "None" ,
      trip_length: form.trip_length,
      setting: form.setting ? form.setting : "None" ,
      //departure_month: form.departure_month,
      months: months,
      country: form.country,
    });
  });
  const classes = useStyles();

  const [tosBoxOpen, setTosBoxOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [months, setMonths] = useState<Array<string>>([]);

  const selectMonth = (event: React.ChangeEvent<HTMLInputElement>, value:boolean) => {
    const selectedMonth = event.target.value;
    if (value) {
      months.push(selectedMonth)
    } else {
      months.forEach ( (item, index) => {
        if (item == selectedMonth) months.splice(index, 1);
      });
    }
  };

  const checkMonths = (months:Array<string>) => {
    var min = 1;
    var max = 12;
    var trip_length = ""
    var inputs = (document.getElementsByName("trip_length-radio"))
    for (let i = 0; i < inputs.length; i++) {
      let input = inputs[i] as HTMLInputElement | null;
      if (input?.checked) {
        trip_length = input.value;
      }
    }
  
    if (trip_length == "C1_Less_than_month") {
      max = 1;
    } else if (trip_length == "C2_one_two_months") {
      max = 2;
    } else if (trip_length == "C3_three_six_months") {
      min = 3;
      max = 6;
    } else if (trip_length == "C4_Long_term") {
      min = 7;
      max = 12;
    };

    return months.length <= max && months.length >= min;
  };

  return (
    <>
    <form onSubmit={submit}>
    <Typography variant="h4" component="h3" paragraph>
        {JE_TITLE}
    </Typography>
    <Typography  paragraph>
        {JE_DESCRIPTION}
    </Typography>
      <Alert key={VERSION_ALERT} severity={"info"} className={classes.message}>
        <AlertTitle>{"Calculator Version"}</AlertTitle>
        {VERSION_ALERT}
      </Alert>
      <Grid container spacing={6} >
        <Grid container item xs={12} md={6} direction="column" > 
          <Typography variant="h5" component="h2">
            {STEP1_TITLE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="country"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {COUNTRY_LABEL}{" "}
                    <span>
                      ({" "}
                      <Link
                        onClick={() => {
                          setMapOpen(true);
                        }}
                      >
                        { MAP_LINK_NAME }
                      </Link>
                      {" "})
                    </span>
                  </FormLabel>
                  <Typography variant="caption" className={classes.ctDescription}>
                    {COUNTRY_SUBLABEL}
                  </Typography>
                  <Select
                    name="country-radio"
                    value={value}
                    onChange={(e, value) => onChange(e.target.value as string)}
                  >
                    {COUNTRY_OPTIONS.sort((a, b) => (a.label > b.label) ? 1 : -1).map(({ value, label }) => (
                      <MenuItem
                        value={value}
                      >{label}</MenuItem>
                    ))}
                  </Select>
                  {errors?.country?.message && (
                    <FormHelperText error>{errors.country.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="setting"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{SETTING_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="setting-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {SETTING_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.setting?.message && (
                    <FormHelperText error>{errors.setting.message}</FormHelperText>
                  )}
                  <Typography variant="caption" className={classes.ctDescription}>
                    {SETTING_SUBLABEL}
                  </Typography>
                </FormControl>
              )}
            />
          </div> 
          <div className={classNames(classes.formComp)}>
            <Controller
              name="trip_length"
              control={control}
              rules={{
                validate: (value) => !!value || FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend" className={classNames(classes.formComp)} >
                    {TRIP_LABEL}
                  </FormLabel>
                  <Typography variant="caption" className={classes.ctDescription}>
                    {TRIP_SUBTITLE}
                  </Typography>
                  <RadioGroup
                    name="trip_length-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {TRIP_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio className={classNames(classes.ctOption)} />}
                        label={
                          <div >
                            <Typography variant="body2" >{label}</Typography>
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {errors?.trip_length?.message && (
                    <FormHelperText error>{errors.trip_length.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="months"
              control={control}
              rules={{
                // validate: (value) =>  months.length > 0 || FIELD_REQUIRED,
                validate: (value) =>  checkMonths(months) || MONTHS_NUMBER
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{MONTH_LABEL}</FormLabel>
                  <FormGroup
                    row
                  >
                    {MONTH_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                      key={label}
                      value={value}
                      control={<Checkbox onChange={(e, value) => selectMonth(e, value)} name={label}/>}
                      label={value}
                    />
                    ))}
                  </FormGroup>
                  {errors?.months?.message && (
                    <FormHelperText error>{errors.months.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
        </Grid>
        <Grid container item xs={12} md={6} direction="column" > 
          <Typography variant="h5" component="h2">
            {STEP2_TITLE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="sex"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{SEX_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="sex-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {SEX_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.sex?.message && (
                    <FormHelperText error>{errors.sex.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="age"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
                // min: { value: 5, message: AGE_TOO_SMALL },
                // max: { value: 17, message: AGE_TOO_BIG },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{AGE_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="age-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {AGE_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.age?.message && (
                    <FormHelperText error>{errors.age.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="comorbidities"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{COMORBIDITIES_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="comorbidities-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {COMORBIDITIES_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.comorbidities?.message && (
                    <FormHelperText error>{errors.comorbidities.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="ppm"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {PPM_LABEL}
                    <br/>
                    {PPM_SUBTITLE}
                  </FormLabel>
                  <RadioGroup
                    row
                    name="ppm-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {PPM_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.ppm?.message && (
                    <FormHelperText error>{errors.ppm.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <Typography variant="h5" component="h2">
            {STEP3_TITLE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="vaccine_status"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{VACCINE_STATUS_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="vaccine_status-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {VACCINE_STATUS_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.vaccine_status?.message && (
                    <FormHelperText error>{errors.vaccine_status.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="vaccine_type"
              control={control}
              rules={{
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{VACCINE_TYPE_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="vaccine_type-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {VACCINE_TYPE_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.vaccine_type?.message && (
                    <FormHelperText error>{errors.vaccine_type.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={tosBoxOpen}
        onClose={() => setTosBoxOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{TOS_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TOS_TEXT />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTosBoxOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classNames(classes.formComp)}>
        <Controller
          name="tos"
          control={control}
          rules={{
            validate: (value) => !!value || FIELD_REQUIRED,
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl component="fieldset">
              <FormLabel component="legend">{TOS_HEADING}</FormLabel>
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} />}
                label={
                  <span>
                    {TOS_1}{" "}
                    <Link
                      onClick={() => {
                        onChange(!value);
                        setTosBoxOpen(true);
                      }}
                    >
                      <b>{TOS_2}</b>
                    </Link>{" "}
                    {TOS_3}
                  </span>
                }
              />
              {errors?.tos?.message && (
                <FormHelperText error>{errors.tos.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </div>
      <Dialog
        fullWidth
        // maxWidth="md"
        style = {{ width:'fitContent', height:'fitContent'}}
        open={mapOpen}
        onClose={() => setMapOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{MAP_TITLE}</DialogTitle> */}
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
            <MAP_TEXT />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMapOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classNames(classes.formComp)}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={submit}
        >
          {SUBMIT_LABEL}
        </Button>
      </div>
    </form>
    </>
  );
}
