import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL + "/v1";

export type JEFormData = {
  tos: boolean;
  age?: string;
  sex: string;
  vaccine_status: string;
  comorbidities: string;
  vaccine_type: string;
  ppm: string;
  trip_length: string;
  setting: string;
  //departure_month: string;
  months: string[];
  country: string;
};

export type JEVCFormData = {
  tos: boolean;
  age?: string;
  sex: string;
  je: string;
  other_vaccine: string;
};

export async function computeJE(form: JEFormData) {
  const res = await axios.post(API_URL + "/compute_je", form);
  return res.data;
}

export async function computeJEVC(form: JEVCFormData) {
  const res = await axios.post(API_URL + "/compute_jevc", form);
  return res.data;
}