import { Typography } from "@material-ui/core";
import {
  makeStyles,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab/";
import {
  DESCRIPTION_LABEL,
  LESS_THAN_TENTH_MILLION,
  LESS_THAN_TENTH_MILLION_IN_X,
  RISK_LABEL,
  RISK_PER_MILLION,
  RISK_TEXT,
  INFOBOX_RISK_TEXT,
  STEPF_SUBMIT_FORM_FIRST,
  STEPF_TITLE,
  ZERO_RISK,
  ZERO_RISK_IN_X,
} from "./constants";
import {
  Bar,
  XAxis,
  YAxis,
  ComposedChart,
  Tooltip,
  LabelList,
  ReferenceLine,
  Text,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  message: {
    margin: "2rem 1rem",
  },
  tableContainer: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  table: {
    minWidth: 650,
  },
  abbr: {
    textDecoration: "none",
  },
  tooltip: {
    padding: "10px",
    backgroundColor: "white",
    border: "1px solid black",
  },
  centeredTabRoot: {
    flexGrow: 1,
    marginBottom: "2em",
  },
}));

type OutputProps = {
  output: any;
};

function formatNumber(value: number) {
  const sigfigs = 2;
  const digits = Math.ceil(Math.log10(value));

  // this avoids some floating point issues!
  const mul = 10 ** (digits - sigfigs);
  const div = 10 ** (sigfigs - digits);
  const rounded = Math.round(value / mul) / div;

  if (rounded < 100) {
    return rounded.toString();
  }

  const [l] = rounded.toString().split(".");
  const out: Array<string> = [];

  for (let i = 1; i <= l.length; ++i) {
    const dig = l[l.length - i];
    out.push(dig);
    if (i % 3 === 0 && i < l.length) {
      out.push(",");
    }
  }

  return out.reverse().join("");
}

function displayRisk(value: number, one_in_x: boolean, label?: string) {
  if (value <= 0.0) {
    if ((typeof label !== 'undefined') && label.includes("dying from COVID-19")) {
      return "No deaths reported in Australia in this group from 18/11/21 to 17/01/22";
    }
    else if (!one_in_x) {
      return "less than  0.0001 (extremely rare)";
    } else {
      return "less than 0.0001 in a million (extremely rare)";
    }
  }
  
  if (!one_in_x && !((typeof label !== 'undefined') && label.includes("adverse event"))) {
    return formatNumber(value * 1e6);
  } else {
    const onval = 1 / value;
    if (onval > 1e6) {
      return "1 in " + formatNumber(onval / 1e6) + " million";
    } else {
      return "1 in " + formatNumber(onval);
    }
  }
}

export function RiskDisplay({
  risk,
  oneInX,
}: {
  risk: number;
  oneInX: boolean;
}) {
  const classes = useStyles();

  const riskPerMillion = risk * 1e6;
  const roundedRisk = displayRisk(risk, oneInX);
  const roundedRiskPerMillionLotsOfDigits =
    Math.round(riskPerMillion * 1e6) / 1e6;

  let textRepresentation = `${roundedRisk} ${RISK_PER_MILLION}`;
  if (oneInX) {
    textRepresentation = `${roundedRisk}`;
  }
  if (riskPerMillion === 0.0) {
    textRepresentation = oneInX ? ZERO_RISK_IN_X : ZERO_RISK;
  } else if (roundedRiskPerMillionLotsOfDigits < 0.1) {
    textRepresentation = oneInX
      ? LESS_THAN_TENTH_MILLION_IN_X
      : LESS_THAN_TENTH_MILLION;
  }

  return (
    <abbr
      title={
        "Model output: " +
        roundedRiskPerMillionLotsOfDigits.toString() +
        " per million"
      }
      className={classes.abbr}
    >
      {textRepresentation}
    </abbr>
  );
}

export default function Form({ output }: OutputProps) {
  const classes = useStyles();

  const [oneInX, setOneInX] = useState(true);
  const [tab, setTab] = useState(0);
  const [relatable, setRelatable] = useState(false);

  const handleChange = (event: any, newTab: any) => {
    setTab(newTab);
    setOneInX(newTab === 0);
  };
  
  return (
    <>     
      <Typography variant="h5" component="h2">
        {STEPF_TITLE}
      </Typography>
      {output ? (
        <>
        <div id="printable" >
          <Typography variant="body1" paragraph>
            {output.clinician_details}
          </Typography>
          <Typography variant="body1" paragraph>
            {output.scenario_description}
          </Typography>
          {output.messages?.map(({ heading, text, severity }: any) => (
            <Alert key={text} severity={severity} className={classes.message}>
              <AlertTitle>{heading}</AlertTitle>
              {text}
            </Alert>
          ))}

          <Paper className={classes.centeredTabRoot}>
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Show risk as a chance" />
              <Tab label="Show risk per million travellers" />
            </Tabs>
          </Paper>
          
          {output.bar_graphs?.map(({ title, subtitle, risks }: any) => {
            let multiplier = 1e6;
            const data_full = risks.map(
              ({ label, risk, is_relatable, is_other_shot , is_vaccinated, bar_text, hover_text, specify_color}: any) => {
                let color = "#413ea0";
                if (is_relatable) {
                  color = "#ccc";
                }
                if (is_other_shot) {
                  color = "#b2b1ce";
                }
                if (is_vaccinated) {
                  color = "#6dcc68"; //green
                } else {
                  color = "#fca847"; //orange
                }
                if (specify_color) {
                  color = specify_color;
                }
                // if no bar_text is specified use the default 
                let display_risk = displayRisk(risk, oneInX, label);
                if (bar_text) {
                  display_risk = bar_text;
                } 
                if (!relatable && is_relatable) {
                  return null;
                }
                return {
                  label,
                  risk: multiplier * risk,
                  fill: color,
                  display_risk: display_risk,
                  hover_text: hover_text,
                };
              }
            );
            const data = data_full.filter((x : any) => x !== null);
            const data_length = data.filter((x : any) => x.label.includes('...') !== true)
            // don't want the toggle to work on side effects plot
            const oneInX_exception = title.includes("side effect")

            const CustomYTickWrap = ({ x, y, payload }: any) => {
              if (payload && payload.value) {
                return (
                  <Text
                      width={350} // less than yaxis length
                      x={x} 
                      y={y} 
                      textAnchor="end" 
                      verticalAnchor="middle"
                      // fontSize={"12px"}
                      // lineHeight={"110%"}
                      // stroke={'#6e6e6e'}
                      fill={'#6e6e6e'}
                  >{payload.value.includes('...')? " " : payload.value}</Text>
                );
              }
              return null;
            };

            
            return (
              <div key={title}>
                <Typography variant="h6" component="h3">
                  {title}
                </Typography>
                <Typography variant="body1" paragraph>
                  {subtitle}
                </Typography>

                <ResponsiveContainer width={1000} height={(oneInX || oneInX_exception)? data_length.length*50  : data_length.length*50 + 110}>
                  <ComposedChart
                    layout="vertical"
                    width={1000}
                    // height={auto}
                    data={data}
                    margin={{
                      top: 20,
                      right: 80,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    
                    <XAxis
                      type="number"
                      label={RISK_TEXT}
                      height={100}
                      hide={oneInX || oneInX_exception}
                    />
                    <>
                    <YAxis
                      dataKey="label"
                      type="category"
                      scale="band"
                      width={400} 
                      tick={<CustomYTickWrap/>} 
                      allowDuplicatedCategory={true}
                    />
                    </>
                    <ReferenceLine y='...' stroke='#6e6e6e' strokeWidth={1}/>
                    <ReferenceLine y='... ' stroke='#6e6e6e' strokeWidth={1}/>
                    <ReferenceLine y='...  ' stroke='#6e6e6e' strokeWidth={1}/>
                    <ReferenceLine y='...   ' stroke='#6e6e6e' strokeWidth={1}/>

                    <Bar dataKey="risk" >
                      {
                        data.map((entry:any, index:any) => {
                          let color = entry.fill;
                          let opacity = 1;
                          if ((String(entry.label).includes('...'))) {
                            color='#fafafa'
                            opacity=0
                          } 
                          return <Cell   
                                height={(String(entry.label).includes('...')) ? 12 : 30} 
                                stroke={color}
                                fill={color}  
                                fillOpacity={opacity}
                                strokeWidth={0}
                              />;
                      })
                      }
                      <LabelList dataKey="display_risk" width={150} position="right" />
                    </Bar>

                    <Tooltip
                      content={({ label, payload, active }) => {
                        if (active && payload && payload.length) {
                          if (label.includes('...')) {
                            return null;
                          }
                          const { value } = payload![0] as any;
                          var bar_properties = payload![0] as any;

                          var tooltip_text;
                          if (bar_properties.payload.hover_text) {
                            tooltip_text = bar_properties.payload.hover_text
                          } else {
                            tooltip_text =  [
                                              // displayRisk(value / 1e6, false, label) 
                                              // + " " + INFOBOX_RISK_TEXT, <br/>,
                                              "This is " 
                                              + displayRisk(value / 1e6, true, label)
                                              + " people."];
                          }
                          
                          return (
                            <div className={classes.tooltip}>
                              {/* {label}
                              <br /> */}
                              {tooltip_text}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }}
                    />
                    
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            );
          })}
          
          {output.printable && (
            <Box py={5}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                href={output.printable.url}
                target="_blank"
              >
                {output.printable.text}
              </Button>
            </Box>
          )}

          {output.output_groups?.map(({ heading, explanation, risks }: any) => (
            <div key={heading}>
              <Typography variant="h6" component="h3">
                {heading}
              </Typography>
              <Typography variant="body1" paragraph>
                {explanation}
              </Typography>
              <TableContainer
                className={classes.tableContainer}
                component={Paper}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{DESCRIPTION_LABEL}</TableCell>
                      <TableCell>{RISK_LABEL}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {risks.map(({ name, risk, comment }: any) => (
                      <TableRow key={name}>
                        <TableCell component="th" scope="row">
                          {name}
                        </TableCell>
                        <TableCell>
                          <RiskDisplay risk={risk} oneInX={oneInX} />
                          <br />(<RiskDisplay risk={risk} oneInX={!oneInX} />)
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
          
          </div>
          <iframe id="printerelem" style={{height:0, width:0, border:0, position:"absolute"}} title="printerelem"></iframe>
          <Box py={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // window.print()
                  // TODO should validate these properly
                  var content = document.getElementById("printable")!; // div to print
                  var p = (document?.querySelector('#printerelem') as HTMLIFrameElement)
                  var printer = p.contentWindow!;
                  printer.document.open();
                  printer.document.write("<h1>Risk Output</h1>");
                  printer.document.write(content.innerHTML);
                  printer.document.close();
                  printer.focus();
                  printer.print();
                  }
                }
              >
                Print Output
              </Button>
          </Box>
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          {STEPF_SUBMIT_FORM_FIRST}
        </Typography>
      )}
      <Box pt={5}>
      <Typography variant="caption" paragraph>
            <b>Contribution </b>
            <br />
            <b> Conception of tool</b>: Colleen L. Lau, Deborah J. Mills, Luis Furuya-Kanamori
            <br />
            <b> Model design</b>: Colleen L. Lau, Deborah J. Mills, Helen Mayfield, Narayan Gyawali, 
                  Brian J. Johnson, Philip N. Britton, Luis Furuya-Kanamori
            <br />
            <b> Data acquistion</b>:  Narayan Gyawali, Brian J. Johnson, 
                  Kasim Allel, Weiping Ling, Luis Furuya-Kanamori
            <br />
            <b>Web development</b>: Tina Moghaddam, Hongen Lu
            <br />
            <br />
          </Typography>
      </Box>
    </>
  );
}
