import {
  Box,
  Container,
  Typography,
  ThemeProvider,
  createTheme,
  CssBaseline,
  AppBar,
  Toolbar,
  Button,
  Grid,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";

const theme = createTheme({
  palette: {
    common: {
      black: "rgb(0,0,0)",
    },
    primary: {
      main: "#59a9c2",
    },
    secondary: {
      main: "rgb(0, 0, 0)",
    },
  },
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    caption: {
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
});

interface SkelProps {
  title: string;
  subtitle: React.ReactNode | string;
  children: React.ReactNode;
}

export default function Skel({ title, subtitle, children }: SkelProps) {
  const isNarrow = window.innerWidth <= 500;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" color="inherit">
        <Container maxWidth="lg">
          <Toolbar>
          <p style={{ flex: 1, color: "#24608a" }}>
              <a href="https://www.uq.edu.au/">
                <img
                  style={{ padding: "10px 0", height: "70px" }}
                  src="./logos/UQlogo-Purple-rgb.png"
                  alt="UQ Logo"
                />
              </a>
            </p>
            <p style={{ color: "#24608a" }}>
              <a href="https://www.crestma.com.au/">
                <img
                  style={{ padding: "1px 0", height: "70px", marginRight: "40px" }}
                  src="./logos/CRESTMA.png"
                  alt="CRESTMA Logo"
                />
              </a>
            </p>
          </Toolbar>
        </Container>
      </AppBar>
      <Box>
        <Box pt={17} pb={2} style={{ backgroundColor: "#E8F4FD" }}>
          <Container maxWidth="lg" style={{
            backgroundSize: "17%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
          }}>
            <Grid container spacing={1}>
              <Grid item xs={10} >
                {/* <Typography variant="caption" paragraph>
                  <p>
                    VaxiCal is a tool to help people make decisions about getting
                    information about vaccine risks ....
                  </p>
                </Typography> */}
                <Box py={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    href="/"
                  >
                    Calculators
                  </Button>{" "}
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    href="/publications"
                  >
                    Publications
                  </Button>{" "}
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    disabled
                    disableElevation
                    href="/faq"
                  >
                    FAQ
                  </Button>{" "} */}
                  {/* <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    href="/moreinfo"
                  >
                    More Info
                  </Button>{" "} */}
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    href="/disclaimer"
                  >
                    Disclaimer
                  </Button>{" "}
                  <Button
                  variant="outlined"
                  color="primary"
                  href="https://uniofqueensland.syd1.qualtrics.com/jfe/form/SV_1RO2Ap10T6RBY4S"
                  rel="noreferrer"
                  target="_blank"
                >
                  FEEDBACK OR COMMENTS
                </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mt={4} mb={2}>
          <Container maxWidth="lg">
            <Typography>
              {children}
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          {/* <Box mb={7}> <b> Collaborating institutions </b> </Box> */}
          <Box>
          <br/>
          <Typography variant="caption" paragraph>
            VaxiCal is a collaboration between <a href="https://www.crestma.com.au/" target="_blank">CRESTMA</a> and  the <a href="https://clinical-research.centre.uq.edu.au/odesi" target="_blank">UQ ODeSI program</a> to develop interactive digital tools to facilitate shared decision making between clinicians and travellers.
          </Typography>
          </Box>
          <Box mt={3} mb={2}>&copy; 2023-2024 CRESTMA.</Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

