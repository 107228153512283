import { useState } from "react";
import InputForm from "./InputForm";
import JEVCForm from "./JEVCForm";
import { 
  computeJE,
  computeJEVC,
  JEFormData,
  JEVCFormData, 
} from "./api";
import Output from "./Output";
import JEVCOutput from "./JEVCOutput";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { BY_LINE, TITLE, DISCLAIMER_TEXT } from "./constants";
import Skel from "./Skel";
import { 
  Button , 
  Typography,   
  Box,
  Container,
} from "@material-ui/core";
import { Link, Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";


function IndexRoute() {
  return (
    <>
      <h1>Choose a risk calculator</h1>
      <Box mb={5}>
      <h2>Risk-benefit calculator for Japanese encephalitis vaccine in travellers, updated 21/02/2024</h2>
      <Button
        component={Link}
        to="/jet"
        color="primary"
        variant="contained"
      >
        JE calculator
      </Button>
      </Box>
      <Box mb={5}>
      <h2>Side effect calculator for Japanese encephalitis vaccine, updated 15/05/2024</h2>
      <Button
        component={Link}
        to="/jevc"
        color="primary"
        variant="contained"
      >
        JE side effect calculator
      </Button>
      </Box>
      <Box mb={5}>
      <h2>Side effect calculator for shingles vaccine, coming soon</h2>
      <Button
        component={Link}
        to="/"
        color="primary"
        variant="contained"
        disabled
      >
        Shingles side effect calculator
      </Button>
      </Box>
      <Box mb={5}>
      <h2>Risk-benefit calculator for yellow fever vaccine in travellers, coming soon</h2>
      <Button
        component={Link}
        to="/"
        color="primary"
        variant="contained"
        disabled
      >
        Yellow fever calculator
      </Button>
      </Box>
      </>
  );
}

function CalculatorRoute() {
  const [error, setError] = useState<string | null>(null);
  const [output, setOutput] = useState<any | null>(null);
  const calculatorCallback = async (form: JEFormData) => {
    setError(null);
    try {
      // form.age = Math.round(form.age!);
      const res = await computeJE(form);
      setOutput(res);
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    }
  };
  return (
    <>
       <Button
        component={Link}
        to="/"
        color="primary"
        variant="outlined"
        size="small"
        style={{ margin: "1em" }}
      >
        Back to calculators
      </Button>
      <InputForm callback={calculatorCallback} />
      {error ? (
        <Alert severity="error">
          <AlertTitle>An error occured</AlertTitle>
          {error}
        </Alert>
      ) : (
        <Output output={output} />
      )}
    </>
  );
}

function JEVCRoute() {
  const [error, setError] = useState<string | null>(null);
  const [jevcOutput, setJEVCOutput] = useState<any | null>(null);
  const jevcCallback = async (form: JEVCFormData) => {
    setError(null);
    try {
      const res = await computeJEVC(form);
      setJEVCOutput(res);
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    }
  };
  return (
    <>
       <Button
        component={Link}
        to="/"
        color="primary"
        variant="outlined"
        size="small"
        style={{ margin: "1em" }}
      >
        Back to calculators
      </Button>
      <JEVCForm callback={jevcCallback} />
      {error ? (
        <Alert severity="error">
          <AlertTitle>An error occured</AlertTitle>
          {error}
        </Alert>
      ) : (
        <JEVCOutput output={jevcOutput} />
      )}
    </>
  );
}

function PubRoute() {
  return (
    <>
      <Button
        component={Link}
        to="/"
        color="primary"
        variant="outlined"
        size="small"
        style={{ margin: "1em" }}
      >
        Back to calculators
      </Button>
      <Box my={4}>
        <h1>Publications</h1>
        <Container maxWidth="lg">
          <h2>Peer Reviewed</h2>
          <Container maxWidth="lg">
          <Typography variant="body1" paragraph>
            Colleen L Lau; Deborah J Mills; Helen Mayfield; Narayan Gyawali; Brian Johnson; Hongen Lu; Kasim Allel; Philip N Britton; 
            Weiping Ling; Tina Moghaddam; Luis Furuya-Kanamori,"A decision support tool for risk-benefit analysis of Japanese encephalitis vaccine in travellers", 
            Journal of Travel Medicine, 2023 Nov 18;30(7):taad113, 
            <a href="https://doi.org/10.1093/jtm/taad113" target="_blank"> doi: 10.1093/jtm/taad113 
            </a>
          </Typography>
          </Container>
        </Container>
      </Box>
    </>
  );
}

function InfoRoute() {
  return (
    <>
      <Button
        component={Link}
        to="/"
        color="primary"
        variant="outlined"
        size="small"
        style={{ margin: "1em" }}
      >
        Back to calculators
      </Button>
      <Box my={4}>
        <h1>More Information</h1>
        <Container maxWidth="lg">
          <Typography>
            <p>
              The pdf files linked in this page contain more information about 
              the models and outputs used in the risk calculators.
            </p>

          </Typography>
        </Container>
      </Box>
    </>
  );
}

function DisclaimerRoute() {
  return (
    <>
      <Button
        component={Link}
        to="/"
        color="primary"
        variant="outlined"
        size="small"
        style={{ margin: "1em" }}
      >
        Back to calculators
      </Button>
      <Box my={4}>
        <h1>Disclaimer</h1>
        <Container maxWidth="lg">
          <Typography>
          <DISCLAIMER_TEXT />
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default function App() {
  return (
    <Skel title={TITLE} subtitle={BY_LINE}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexRoute />} />
          <Route path="/jet" element={<CalculatorRoute />} />
          <Route path="/jevc" element={<JEVCRoute />} />
          <Route path="/publications" element={<PubRoute />} />
          <Route path="/moreinfo" element={<InfoRoute />} />
          <Route path="/disclaimer" element={<DisclaimerRoute />} />
        </Routes>
      </BrowserRouter>
    </Skel>
  );
}
