// top of page
export const TITLE = "VaxiCal";
export const BY_LINE = "By, credits, etc";

// form elements
export const FIELD_REQUIRED = "This field is required.";
export const MONTHS_NUMBER = "Select the right number of months for your trip duration";

export const STEP1_TITLE = "Itinerary";
export const STEP2_TITLE = "Demographic characteristics";
export const STEP3_TITLE = "Japanese encephalitis vaccine";

export const PPM_LABEL = "How often do you think you will use personal protective against mosquitoes (e.g., repellent)?";
export const PPM_SUBTITLE = "";
export const PPM_OPTIONS = [
  {
    label: "Always",
    value: "C1_LOTS",
  },
  {
    label: "Sometimes (>50% of the time)",
    value: "C2_SOME",
  },
  {
    label: "Seldom",
    value: "C3_NONE",
  },
];

export const TRIP_LABEL = "Duration of travel";
export const TRIP_SUBTITLE = "For multi-country trips, please select the total duration of the trip in JE risk countries";
export const TRIP_OPTIONS = [
  {
    label: "<1 month",
    value: "C1_Less_than_month",
  },
  {
    label: "1-2 months",
    value: "C2_one_two_months",
  },
  {
    label: "3-6 months",
    value: "C3_three_six_months",
  },
  {
    label: ">6 months",
    value: "C4_Long_term",
  },
];

export const SETTING_LABEL = "Areas visiting within the country ";
export const SETTING_SUBLABEL = "If unsure, select ‘part urban, part rural’";
export const SETTING_OPTIONS = [
  {
    value: "C1_URBAN",
    label: "Urban (city)",
  },
  {
    value: "C2_HALF_EACH",
    label: "Part urban, part rural",
  },
  {
    value: "C3_RURAL",
    label: "Rural (farming/ wilderness)",
  },
];

export const MONTH_LABEL = "Months of travel in the selected country";
export const MONTH_OPTIONS = [
  { value: "Jan", label: "January", },
  { value: "Feb", label: "February", },
  { value: "Mar", label: "March", },
  { value: "Apr", label: "April", },
  { value: "May", label: "May", },
  { value: "Jun", label: "June", },
  { value: "Jul", label: "July", },
  { value: "Aug", label: "August", },
  { value: "Sep", label: "September", },
  { value: "Oct", label: "October", },
  { value: "Nov", label: "November", },
  { value: "Dec", label: "December", },
];

export const COUNTRY_LABEL = "Country of destination";
export const COUNTRY_SUBLABEL = "The system can only handle one destination at the time. For multi-country trips, please select the country with the highest risk of JE ";
export const COUNTRY_OPTIONS = [
  { value: "Australia", label: "Australia", },
  { value: "Bangladesh", label: "Bangladesh", },
  { value: "Cambodia", label: "Cambodia", },
  { value: "China_Taiwan", label: "China", },
  { value: "SouthKorea", label: "South Korea", },
  { value: "India", label: "India", },
  { value: "Indonesia", label: "Indonesia", },
  { value: "Japan", label: "Japan", },
  { value: "Laos", label: "Laos", },
  { value: "Malaysia", label: "Malaysia", },
  { value: "Myanmar", label: "Myanmar", },
  { value: "Nepal", label: "Nepal", },
  { value: "PNG", label: "Papua New Guinea", },
  { value: "Philippines", label: "Philippines", },
  { value: "NorthKorea", label: "North Korea", },
  { value: "Singapore", label: "Singapore", },
  { value: "SriLanka", label: "Sri Lanka", },
  { value: "Thailand", label: "Thailand", },
  { value: "Timore_Leste", label: "Timor-Leste", },
  { value: "VietNam", label: "Vietnam", },
  { value: "Bhutan", label: "Bhutan", },
  { value: "Brunei", label: "Brunei", },
];

export const VACCINE_STATUS_LABEL = "What best describe your situation now?";
export const VACCINE_STATUS_OPTIONS = [
  // {
  //   value: "C1_Yes_UptoDate",
  //   label: "Vaccinated, up to date",
  // },
  {
    value: "C3_NO",
    label: "Never vaccinated",
  },
  {
    value: "C2_Yes_NotUpToDate",
    label: "Vaccinated, but not up to date",
  },
];

export const COMORBIDITIES_LABEL = "Do you have hypertension, diabetes mellitus, stroke, and/or renal disease?";
export const COMORBIDITIES_OPTIONS = [
  {
    value: "C2_YES",
    label: "Yes",
  },
  {
    value: "C1_NO",
    label: "No",
  },
];

export const VACCINE_TYPE_LABEL = "What is the proposed vaccine?";
export const VACCINE_TYPE_OPTIONS = [
  // {
  //   value: "NONE",
  //   label: "NONE",
  // },
  {
    value: "IMOJEV",
    label: "Imojev",
  },
  {
    value: "JESPECT",
    label: "JEspect",
  },
];


export const AGE_LABEL = "Age";
export const AGE_OPTIONS = [
  {
    value: "C1_Under_five",
    label: "<5 years",
  },
  {
    value: "C2_Age_5_17",
    label: "5-17 years",
  },
  {
    value: "C3_Age18_60",
    label: "18-60 years",
  },
  {
    value: "C4_Age_60plus",
    label: ">60 years",
  },
];

export const AGE_OPTIONS_JEVC = [
  {
    value: "1",
    label: "<5 years",
  },
  {
    value: "2",
    label: "5-14 years",
  },
  {
    value: "3",
    label: "15-29 years",
  },
  {
    value: "4",
    label: "30-49 years",
  },
  {
    value: "5",
    label: ">=50 years",
  },
];

export const SEX_LABEL = "Sex";
export const SEX_OPTIONS = [
  {
    value: "female",
    label: "Female",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const SEX_OPTIONS_JEVC = [
  {
    value: "0",
    label: "Female",
  },
  {
    value: "1",
    label: "Male",
  },
];

export const JE_VACCINE = "Japanese encephalitis vaccine";;
export const JE_OPTIONS = [
  {
    value: "0",
    label: "Imojev",
  },
  {
    value: "1",
    label: "Jespect",
  },
]
export const OTHER_VACCINE = "Vaccine to be co-administered";
export const OTHER_VACCINE_OPTIONS = [
  {
    value: "1",
    label: "None",
  },
  {
    value: "2",
    label: "DPT (Diphtheria / Pertussis / Tetanus)",
  },
  {
    value: "3",
    label: "Hepatitis A/B",
  },
  {
    value: "4",
    label: "Influenza",
  },
  {
    value: "5",
    label: "MMR (Measles / Mumps / Rubella)",
  },
  {
    value: "6",
    label: "Meningococcal",
  },
  {
    value: "7",
    label: "Rabies",
  },
  {
    value: "8",
    label: "Typhoid",
  },
]

export const VERSION_ALERT = `Last updated on 21/02/2024.`;
export const VERSION_ALERT_JEVC = `Last updated on 15/05/2024.`;
export const SUBMIT_LABEL_SEP = "Calculate risk";
export const SUBMIT_LABEL = "Calculate risk";

// outputs
export const STEPF_TITLE = "Risk output";
export const STEPF_SUBMIT_FORM_FIRST = "Please submit the form for output.";

export const DESCRIPTION_LABEL = "Description";
export const RISK_LABEL = "Risk";
export const COMMENT_LABEL = "Comment";

export const RISK_PER_MILLION = "in a million";
export const LESS_THAN_TENTH_MILLION = "Less than 0.1 in a million";
export const ZERO_RISK = LESS_THAN_TENTH_MILLION;
export const LESS_THAN_TENTH_MILLION_IN_X = "Less than 1 in 10,000,000";
export const ZERO_RISK_IN_X = LESS_THAN_TENTH_MILLION_IN_X;

export const RISK_TEXT = "Number of cases per million travellers";
export const INFOBOX_RISK_TEXT = "cases per million travellers";
export const INFOBOX_RISK_TEXT_JEVC = "cases per million people";

export const TOS_HEADING = "Important information";
export const TOS_1 = "I have read and agree to the";
export const TOS_2 = "Terms and Conditions";
export const TOS_3 = "for using the calculator.";

export const TOS_TITLE = "Terms and Conditions";
export const JE_TITLE = "Risk-benefit calculator for Japanese encephalitis vaccine in travellers";
export const JE_DESCRIPTION = "JE vaccine risk–benefit tool is an interactive, scenario-based online application that encompasses \
      key traveller’s characteristics available at the time of a pre-travel medical consultation (e.g. age, destination, season, \
        and duration of the trip) to estimate the risk of developing symptomatic disease and clinical outcomes, the effectiveness of \
        vaccination in preventing these outcomes, and the risk of adverse events following immunisations.";


export const JEVC_TITLE = "Side effect calculator for Japanese encephalitis vaccine";
export const JEVC_DESCRIPTION = "The Side effect calculator for Japanese encephalitis vaccine estimates the risk of adverse events following immunisation \
      in concomitant administration of Japanese encephalitis and other vaccines. It is an interactive, scenario-based online application that encompasses \
      age group, sex, JE vaccine and vaccine to be co-administered to estimate the risk of adverse events following immunisations.";

export function TOS_TEXT() {
  return (
    <>
      <p>
      The Japanese Encephalitis vaccine risk-benefit Tool 
      (JET) aims to provide estimates of the benefits versus the risks of 
      Japanese encephalitis vaccines to facilitate decision-making by travellers 
      and clinicians. 
      </p>
      <p>
        JET estimates a traveller's personal risks of acquiring Japanese 
        encephalitis infection and developing clinical outcomes, and the 
        potential benefits and risks of Japanese encephalitis vaccination. 
        The risks are calculated using a model based on the available evidence, 
        but the model estimates inevitably come with some level of uncertainty 
        and limitations.
      </p>
      <p>
        JET does not provide advice on medical management. The members of the 
        JET development team accept no responsibility for the outcomes of any 
        actions, decisions, or advice provided, based on information obtained 
        from using JET.
      </p>
      <p>
        Users accept all risks associated with using JET. Users must exercise 
        their own independent clinical skill and judgment, or seek professional 
        clinical advice before relying on information contained in JET when 
        making a clinical decision.
      </p>
      <p>
        The members of the JET development team do not make any warranty or 
        representation in relation to the accuracy, currency or completeness of 
        any information contained in JET or on the website, and do not accept 
        any legal liability or responsibility for any injury, loss or damage 
        incurred by use, reliance or interpretation of the information 
        contained in JET.
      </p>
      <p>
        JET may be found in third parties' programs or materials. This does not 
        imply an endorsement or recommendation by the University of Queensland, 
        and the members of the JET development team for such third parties' 
        organisations, products, services, materials, or information. Any use 
        of JET by another organisation is at its own risk.
      </p>
      <p>
        The entire contents of JET and the website are subject to copyright protection. 
      </p>
      <p>
        JET does not require users to provide any personal information and 
        does not collect users' personal information. JET has been designed 
        to be used anonymously. 
      </p>
      <p>
        The website uses cookies to improve users' experience, and to collect 
        de-identified data about the use of JET. By using JET, you consent to 
        JET collecting de-identified data for the purposes of analysis and 
        evaluation. These data may be collected from user inputs and from user 
        surveys, noting that completion of any user surveys in JET is optional. 
      </p>
    </>
  );
}

export function TOS_TEXT_JEVC() {
  return (
    <>
      <p>
      The Side effect calculator for Japanese encephalitis vaccine 
      (JEVC) aims to provide estimates of the probability of adverse event following immunidation (AEFI) risks of 
      Japanese encephalitis vaccine and other vaccines to facilitate decision-making by patients 
      and clinicians. 
      </p>
      <p>
        JEVC estimates a patient's personal risks of AEFI. 
        The risks are calculated using a model based on the available data, 
        but the model estimates inevitably come with some level of uncertainty 
        and limitations.
      </p>
      <p>
        JEVC does not provide advice on medical management. The members of the 
        JEVC development team accept no responsibility for the outcomes of any 
        actions, decisions, or advice provided, based on information obtained 
        from using JEVC.
      </p>
      <p>
        Users accept all risks associated with using JEVC. Users must exercise 
        their own independent clinical skill and judgment, or seek professional 
        clinical advice before relying on information contained in JEVC when 
        making a clinical decision.
      </p>
      <p>
        The members of the JEVC development team do not make any warranty or 
        representation in relation to the accuracy, currency or completeness of 
        any information contained in JEVC or on the website, and do not accept 
        any legal liability or responsibility for any injury, loss or damage 
        incurred by use, reliance or interpretation of the information 
        contained in JEVC.
      </p>
      <p>
        JEVC may be found in third parties' programs or materials. This does not 
        imply an endorsement or recommendation by the University of Queensland, 
        and the members of the JEVC development team for such third parties' 
        organisations, products, services, materials, or information. Any use 
        of JEVC by another organisation is at its own risk.
      </p>
      <p>
        The entire contents of JEVC and the website are subject to copyright protection. 
      </p>
      <p>
        JEVC does not require users to provide any personal information and 
        does not collect users' personal information. JEVC has been designed 
        to be used anonymously. 
      </p>
      <p>
        The website uses cookies to improve users' experience, and to collect 
        de-identified data about the use of JEVC. By using JEVC, you consent to 
        JEVC collecting de-identified data for the purposes of analysis and 
        evaluation. These data may be collected from user inputs and from user 
        surveys, noting that completion of any user surveys in JEVC is optional. 
      </p>
    </>
  );
}

export function DISCLAIMER_TEXT() {
  return (
  <>
      <p>
      Please read carefully and completely the terms of the agreement that follows. By accessing this Website you agree to be bound by these terms. 
      If you do not wish to be bound to these terms, you must not access the site. 
      </p>
      <p>
      The VaxiCal site is not intended to be used for clinical decision making, which is beyond the scope of this website. The purpose of this site is to 
      provide basic information about vaccinations, and their risks of adverse event following immunisation. The team does not represent or warrant that the content of 
      this website is current, accurate, authentic or complete or that the information contained in this website is suitable for your needs. 
      You should assess whether the information is accurate, authentic or complete and, where appropriate, seek independent professional advice.
      </p>
      <p>
      This site may contain links and references to other resources which are recommended that you familiarise yourself with. Your use of any external website is also 
      governed by the terms of that website. VaxiCal team does not control and accepts no liability for the content 
      of those websites, or for any loss arising from the use or reliance on those websites. The provision of a link to an external website does not authorise you 
      to reproduce, adapt, modify, communicate or in any way deal with the material on that site.
      </p>
  </>
  )
}

export const MAP_TITLE = "";
export const MAP_LINK_NAME = "JE risk map";

export function MAP_TEXT() {
  return(
    <>
      <p style={{display:'flex', justifyContent:'center' }}>
        <img
          style={{ maxWidth:'100%', maxHeight: '70vh' }}
          // style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
          src="/docs/JET_Map2.png" 
          alt="Map of effected areas"
        />
      </p>
    </>
  )
}