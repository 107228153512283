import {
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Select,
  MenuItem,
  Grid,
  FormGroup,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab/";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

import { JEVCFormData } from "./api";
import {
  TITLE,
  VERSION_ALERT_JEVC,
  STEP1_TITLE,
  STEP2_TITLE,
  AGE_LABEL,
  AGE_OPTIONS_JEVC,
  SEX_LABEL,
  SEX_OPTIONS_JEVC,
  JE_VACCINE,
  JE_OPTIONS,
  OTHER_VACCINE,
  OTHER_VACCINE_OPTIONS,
  FIELD_REQUIRED,
  SUBMIT_LABEL,
  TOS_HEADING,
  TOS_1,
  TOS_2,
  TOS_3,
  TOS_TITLE,
  TOS_TEXT,
  TOS_TEXT_JEVC,
  JEVC_TITLE,
  JEVC_DESCRIPTION,
} from "./constants";
import { useState } from "react";
// import { getValue } from "@testing-library/user-event/dist/utils";

const useStyles = makeStyles((theme) => ({
  formComp: {
    marginTop: "0.5rem",
    marginBottom: "0.7rem",
  },
  indent: {
    paddingLeft: "2rem",
  },
  ctOption: {
    marginTop: "0rem",
    marginBottom: "0rem",
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  ctDescription: {
    color: "#777",
    fontSize: "0.9rem",
  },
  message: {
    margin: "2rem 1rem",
  },
}));

type FormInputs = {
  callback: (form: JEVCFormData) => void;
};

export type JEVCFullFormData = {
  tos: boolean;
  age?: string; 
  sex?: string;
  je: string;
  other_vaccine: string;
};

export default function Form({ callback }: FormInputs) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<JEVCFullFormData>({
    mode: "onBlur",
    defaultValues: {
    },
  });
  const submit = handleSubmit((form: JEVCFullFormData) => {
    callback({
      tos: form.tos,
      age: form.age ? form.age : "None",
      sex: form.sex ? form.sex : "None",
      je: form.je ? form.je : "None",
      other_vaccine: form.other_vaccine,
    });
  });
  const classes = useStyles();

  const [tosBoxOpen, setTosBoxOpen] = useState(false);

  return (
    <>
    <form onSubmit={submit}>
    <Typography variant="h4" component="h3" paragraph>
        {JEVC_TITLE}
    </Typography>
    <Typography  paragraph>
        {JEVC_DESCRIPTION}
    </Typography>
      <Alert key={VERSION_ALERT_JEVC} severity={"info"} className={classes.message}>
        <AlertTitle>{"Calculator Version"}</AlertTitle>
        {VERSION_ALERT_JEVC}
      </Alert>
      <Grid container spacing={6} >
        <Grid container item xs={12} md={6} direction="column" > 
          <Typography variant="h5" component="h2">
            {STEP2_TITLE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="sex"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{SEX_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="sex-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {SEX_OPTIONS_JEVC.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.sex?.message && (
                    <FormHelperText error>{errors.sex.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="age"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">{AGE_LABEL}</FormLabel>
                  <RadioGroup
                    row
                    name="age-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {AGE_OPTIONS_JEVC.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.age?.message && (
                    <FormHelperText error>{errors.age.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
        </Grid>
        <Grid container item xs={12} md={6} direction="column" > 
          <Typography variant="h5" component="h2">
            {JE_VACCINE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="je"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="je-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {JE_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.je?.message && (
                    <FormHelperText error>{errors.je.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
          <Typography variant="h5" component="h2">
            {OTHER_VACCINE}
          </Typography>
          <div className={classNames(classes.formComp)}>
            <Controller
              name="other_vaccine"
              control={control}
              rules={{ 
                required: FIELD_REQUIRED,
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="other-vaccine-radio"
                    onChange={(e, value) => onChange(value)}
                    value={value}
                  >
                    {OTHER_VACCINE_OPTIONS.map(({ value, label }) => (
                      <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {errors?.other_vaccine?.message && (
                    <FormHelperText error>{errors.other_vaccine.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={tosBoxOpen}
        onClose={() => setTosBoxOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{TOS_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TOS_TEXT_JEVC />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTosBoxOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classNames(classes.formComp)}>
        <Controller
          name="tos"
          control={control}
          rules={{
            validate: (value) => !!value || FIELD_REQUIRED,
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl component="fieldset">
              <FormLabel component="legend">{TOS_HEADING}</FormLabel>
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} />}
                label={
                  <span>
                    {TOS_1}{" "}
                    <Link
                      onClick={() => {
                        onChange(!value);
                        setTosBoxOpen(true);
                      }}
                    >
                      {TOS_2}
                    </Link>{" "}
                    {TOS_3}
                  </span>
                }
              />
              {errors?.tos?.message && (
                <FormHelperText error>{errors.tos.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </div>
      <div className={classNames(classes.formComp)}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={submit}
        >
          {SUBMIT_LABEL}
        </Button>
      </div>
    </form>
    </>
  );
}
